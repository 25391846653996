import React, { useEffect, useState, useRef } from "react";

import Cookies from 'universal-cookie';

import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'

import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';

import FadeIn from 'react-fade-in';
import LinesEllipsis from 'react-lines-ellipsis'
import './alerts.css';
import { Button } from "@mui/material";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import * as DOMPurify from 'dompurify';

export default function Alerts(props) {

    const theme = createTheme({
        palette: {
          primary: {
            main: "#ff8c00"
          },
          secondary: {
            main: "#4caf50",
          },
        },
      });

    const cookies = new Cookies(null, { path: '/' });
    const setCookies = (cookieData) => {
        cookies.set('alertIds', cookieData);
    }

    const parse = require('html-react-parser');

    // For retrieving alerts on load
    useEffect(() => {
        fetch("/api/alerts", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => response.json())
        .then(
            res => {
                setNotifications(res)

                let cookieData = {
                    viewed: [],
                    dismissed: []
                };
                if(cookies.get('alertIds')) {
                    cookieData = JSON.parse(JSON.stringify(cookies.get('alertIds')));
                }
                calculateBadgeCount(res, cookieData)
            }
        )
        .catch((error) => {
            toast.error("An error occurred while fetching the new alerts list.", {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }, [])

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        minWidth: '50%',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        //border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };

    const [toggleNotification, setToggleNotification] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [badgeCount, setBadgeCount] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState({})

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setToggleNotification(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const getRelativeTime = (utcTimestamp) => {
        dayjs.extend(relativeTime)
        return dayjs(utcTimestamp).fromNow()
    }

    const handleNotificationBellClick = () => {
        setToggleNotification(!toggleNotification)
    }

    const handleAlertItemClick = (content) => {
        setToggleNotification(false)
        setModalContent(content)
        setModalOpen(true)
        if(!cookies.get('alertIds')){
            const cookieData = {
                viewed: [parseInt(content.id)],
                dismissed: []
            };
            setCookies(JSON.stringify(cookieData))
            calculateBadgeCount(notifications, cookieData)
        } else {
            let cookieData = JSON.parse(JSON.stringify(cookies.get('alertIds')));
            if(!cookieData.viewed.includes(parseInt(alert.id))) {
                cookieData.viewed.push(parseInt(content.id, 10))
            }
            setCookies(JSON.stringify(cookieData))
            calculateBadgeCount(notifications, cookieData)
        }
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const calculateBadgeCount = (notifs, cookieData) => {
        const newNumber = notifs.filter((alert) => !cookieData.viewed.includes(parseInt(alert.id))).length;
        setBadgeCount(newNumber)
    }

    const handleDismissAlert = (id) => {
        setModalOpen(false)
        if(!cookies.get('alertIds')){
            const cookieData = {
                viewed: [],
                dismissed: [parseInt(id)]
            };
            setCookies(JSON.stringify(cookieData))
        } else {
            let cookieData = JSON.parse(JSON.stringify(cookies.get('alertIds')));
            cookieData.dismissed.push(parseInt(id, 10))
            setCookies(JSON.stringify(cookieData))
        }
    }

    const getIconForAlertType = (type, isNew) => {
        let color = "inherit";
        if(isNew) color = "primary";
        switch(type) {
        case 'NEW_FEATURE':
            return <AnnouncementIcon color={color} />;
        case 'OUTAGE':
            return <CrisisAlertIcon  color={color} />;
        case 'SYSTEM_MAINTENANCE':
            return <EngineeringIcon  color={color} />;
        default:
            return <MailIcon  color={color} />;
        }
    }

    const getVisibleNotificationsCount = () => {
        let cookieData = {
            viewed: [],
            dismissed: []
        };
        if(cookies.get('alertIds')) {
            cookieData = JSON.parse(JSON.stringify(cookies.get('alertIds')));
        }
        return notifications.filter(x => !cookieData.dismissed.includes(parseInt(x.id))).length;
    }

    return (
        <ThemeProvider theme={theme}>
            <div ref={ref}>
                <div onClick={handleNotificationBellClick}>
                    <a className="dropdown-toggle">
                        <Badge badgeContent={badgeCount} color="secondary" overlap="circular" max={99}>
                            <NotificationsIcon  color="primary"  />
                        </Badge>
                    </a>
                </div>

                {toggleNotification && (
                    <div
                        style={{
                            color: "#676a6c",
                            padding: "0"
                        }}
                        className={"notificationBar dropdown-menu dropdown-alerts show"}
                    >
                        <div style={{ display: "flex" }}>
                            <div style={{ fontSize: "14px", textAlign: "left", width: "100%", padding: "10px", letterSpacing: "1px", }}>
                                Notifications
                            </div>
                        </div>
                        <FadeIn>
                        { notifications.map((i, k) => {
                            let cookieData = {
                                viewed: [],
                                dismissed: []
                            };
                            if(cookies.get('alertIds')) {
                                cookieData = JSON.parse(JSON.stringify(cookies.get('alertIds')));
                            }

                            if(cookieData.dismissed.includes(parseInt(i.id))) {
                                return null;
                            }

                            let alertClass = "alertItem"
                            const isNew = !cookieData.viewed.includes(parseInt(i.id));
                            if(isNew) alertClass = "alertItem new"

                            return (
                                <div key={k} className={alertClass} onClick={ e=> handleAlertItemClick(i)}>
                                    <div className="icon">
                                        {getIconForAlertType(i.type, isNew)}
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <LinesEllipsis
                                            text={i.summary}
                                            maxLine='2'
                                            ellipsis='...'
                                            trimRight
                                            basedOn='letters'
                                            />
                                        </div>
                                        <div className="timestamp">
                                            <span className="text-muted small timestamp">{getRelativeTime(i.timestamp)}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {
                            getVisibleNotificationsCount() == 0 && (
                                <div className="emptyNotification">
                                    <NotificationsNoneIcon  color="primary"  style={{ fontSize: "64px",}}/>
                                    <div className="title">You're all caught up!</div>
                                    <div className="text-muted timestamp">Check this section for features, outages, system maintenance, and general alerts.</div>
                                </div>
                            )
                        }
                        </FadeIn>
                    </div>
                    )}

                <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalOpen}
                        onClose={handleModalClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                        }}
                        className="alert-modal"
                    >
                    <Fade in={modalOpen}>
                    <Box sx={modalStyle}>
                        <div>
                            <div style={{ display: 'flex', }}>
                                <div id="modal-header-icon">{getIconForAlertType(modalContent.type, true)}</div>
                                <div id="modal-header-text"><Typography id="transition-modal-title" variant="h6" component="h2" className="overflow-word-class">
                                    {modalContent.summary}
                                    </Typography>
                                </div>
                            </div>
                            <Divider textAlign="right">
                                <Typography variant="overline" display="inline" gutterBottom noWrap>
                               {dayjs(modalContent.timestamp).format('DD MMMM YYYY hh:mma')}
                               </Typography>
                               <Typography id={"alert-relative-time"} variant="overline" display="inline" gutterBottom noWrap >
                               {" (" +getRelativeTime(modalContent.timestamp)+")"}
                               </Typography>
                            </Divider>
                        </div>
                        <div>
                        <Typography id="alert-modal-description" sx={{ mt: 2 }} className="overflow-word-class">
                            {parse(DOMPurify.sanitize(modalContent.content))}
                        </Typography>
                        </div>
                        <Button className="alertModalBtn dismissBtn" onClick={e => handleDismissAlert(modalContent.id)}>Dismiss</Button>
                    </Box>
                    </Fade>
                </Modal>
                <ToastContainer />
            </div>
        </ThemeProvider>
    );
}