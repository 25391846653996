import React, { useState, } from "react";

import AnnouncementIcon from '@mui/icons-material/Announcement';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import MailIcon from '@mui/icons-material/Mail';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { toast } from 'react-toastify';

import * as DOMPurify from 'dompurify';
import * as dayjs from 'dayjs';

export default function AddEditScreen(props) {
    const { selectedAlert, countryList, returnCallback, siteList, handleFormSubmission } = props;

    const alertTypes = ['NEW_FEATURE', 'OUTAGE', 'SYSTEM_MAINTENANCE', 'DEFAULT']

    const paymentGatewayMap = [
        {
            country: 'AU',
            paymentGateways: ['Latpay', 'Till', 'Stripe', 'Paypal']
        },
        {
            country: 'EU',
            paymentGateways: ['Latpay', 'Till', 'MyPos']
        },
        {
            country: 'US',
            paymentGateways: ['Cardconnect', 'Datacap/Evo']
        },
        {
            country: 'NZ',
            paymentGateways: ['Verifone', 'Stripe']
        },
    ]

    const euCountryList = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE',
        'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT',
         'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB']

    const productList = [
        {
            label: 'All Products',
            code: 'ALL'
        },
        {
            label: 'Kiosk Only',
            code: 'KIOSK_ONLY'
        },
        {
            label: 'Mobile Payments',
            code: 'MOBILE_PAY'
        }
    ]

    const parse = require('html-react-parser');

    const getIconForAlertType = (type) => {
        const color = "primary";
        const iconStyle = {
            marginRight: '20px',
        }
        switch(type) {
            case 'NEW_FEATURE':
                return <AnnouncementIcon color={color} style={iconStyle}/>;
            case 'OUTAGE':
                return <CrisisAlertIcon  color={color} style={iconStyle} />;
            case 'SYSTEM_MAINTENANCE':
                return <EngineeringIcon  color={color} style={iconStyle} />;
            default:
                return <MailIcon  color={color} style={iconStyle} />;
        }
    }

    const getLabelForAlertType = (type) => {
        switch(type) {
            case 'NEW_FEATURE':
                return "New Feature";
            case 'OUTAGE':
                return "Outage";
            case 'SYSTEM_MAINTENANCE':
                return "System Maintenance";
            default:
                return "Default";
        }
    }

    let initGatewayList = ['ALL', 'Latpay', 'Till', 'Stripe', 'Latpay', 'Till', 'MyPos', 'Cardconnect', 'Datacap/Evo', 'Verifone', 'Stripe', 'Paypal'];
    initGatewayList = [...new Set(initGatewayList)];
    initGatewayList.sort();

    const initiaValues = JSON.stringify(selectedAlert) !== '{}' ? selectedAlert : false;
    const initialId = initiaValues ? initiaValues.id :  0;
    const initialType = initiaValues ? initiaValues.type : 'DEFAULT';
    const initialExpiry = initiaValues ? dayjs(initiaValues.expiry) : dayjs();
    const initialContent = initiaValues ? initiaValues.content : '';
    const initialSummary = initiaValues ? initiaValues.summary : '';
    const initialCountry = initiaValues ? initiaValues.targetCountry : 'ALL';
    const initialGateway = initiaValues ? initiaValues.targetGateway : 'ALL';
    const initialProduct = initiaValues ? initiaValues.targetProducts : 'ALL';

    const [selectedId, setSelectedId] = useState(initialId)
    const [selectedType, setSelectedType] = useState(initialType)
    const [expiryDate, setExpiryDate] = useState(initialExpiry)
    const [summary, setSummary] = useState(initialSummary)
    const [summaryChanged, setSummaryChanged] = useState(false)
    const [contentData, setContentData] = useState(initialContent)
    const [contentChanged, setContentChanged] = useState(false)
    const [selectedRegion, setSelectedRegion] = useState(countryList.find(e => e.code==initialCountry))
    const [paymentGatewayList, setPaymentGatewayList] = useState(initGatewayList)
    const [selectedGateways, setSelectedGateways] = useState(initialGateway)
    const [selectedProduct, setSelectedProduct] = useState(productList.find(e => e.code==initialProduct))
    const isInvalid = selectedType == ''
            || !expiryDate || !dayjs(expiryDate).isValid()
            || !summary || summary == ""
            || !contentData || contentData == ""
            || !selectedRegion || !countryList.find(e => e.code==selectedRegion.code)
            || !selectedGateways || !paymentGatewayList.find(e => e==selectedGateways)
            || !selectedProduct || !productList.find(e => e.code==selectedProduct.code);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [errorDate, setErrorDate] = useState(null);

    const errorDateMessage = React.useMemo(() => {
        if(!expiryDate) return 'Expiry date cannot be empty'

      switch (errorDate) {
        case 'empty': {
            return 'Expiry date cannot be empty'
        }

        case 'minDate': {
          return 'Please select a date that is at least after today';
        }

        case 'invalidDate': {
          return 'Your date is not valid';
        }

        default: {
          return '';
        }
      }
    }, [errorDate]);

    const handleContentDataChange = (e) => {
        setContentChanged(true)
        setContentData(e.target.value)
    }

    const handleRegionChange = (event, newValue) => {
        setSelectedRegion(newValue)
        generatePaymentGatewayList(newValue)
    }


    const handleGatewayChange = (event, newValue) => {
        setSelectedGateways(newValue)
    }

    const generatePaymentGatewayList = (region) => {
        let newGatewayList = [];

        if(region.code == "ALL") {
            newGatewayList = initGatewayList;
        } else {
            let gatewayList = paymentGatewayMap.find((element) => {
                let code = region.code;
                if(euCountryList.includes(code)) code = 'EU';
                return element.country == code
            });
            if(gatewayList){
                newGatewayList = gatewayList.paymentGateways
            }
        }

        let unique = [...new Set([...newGatewayList, "ALL"])];
        unique.sort();
        setPaymentGatewayList(unique)
    }

    const handleSelectedProductChange = (event, newValue) => {
        setSelectedProduct(newValue)
    }

    const handleAddBtnClick = () => {

        const csrfKey = document.getElementById("csrftoken_").name
        const csrfValue = document.getElementById("csrftoken_").value
        const csrfParamsKey =document.getElementById("params_").name
        const csrfParamsValue =document.getElementById("params_").value

        const formData = new FormData();
        formData.append("id", selectedId)
        formData.append("type", selectedType)
        formData.append("expiry", expiryDate.format('YYYY-MM-DD HH:mm:ss') )
        formData.append("summary", summary)
        formData.append("content", contentData)
        formData.append("targetCountry", selectedRegion.code)
        formData.append("targetGateway", selectedGateways)
        formData.append("targetProducts", selectedProduct.code)
        formData.append(csrfKey, csrfValue)
        formData.append(csrfParamsKey, csrfParamsValue)

        handleFormSubmission(formData)
    }

    const isDebug = false;

    return (
        <div id="addEditScreen">
            <Typography variant="h5" gutterBottom>
                Add an alert
            </Typography>
            <div className={"addAlertBtns"} style={{ float: "right", }}>
                <Button disabled={isInvalid} className={"saveBtn"} variant="contained" size="small" color="primary" onClick={handleAddBtnClick}>Save</Button>
                <Button className={"backBtn"} variant="contained" size="small" color="info" onClick={returnCallback}>Back</Button>
            </div>
            {isDebug && <div>
            "id", {JSON.stringify(selectedId)}
            "type", {JSON.stringify(selectedType)}
            "expiry", {JSON.stringify(expiryDate)}
            "summary", {JSON.stringify(summary)}
            "content", {JSON.stringify(contentData)}
            "targetCountry", {JSON.stringify(selectedRegion)}
            "targetGateway", {JSON.stringify(selectedGateways)}
            "targetProducts", {JSON.stringify(selectedProduct)}
            <br/>
            isInvalid = {''+ selectedType === ''
            || !expiryDate || !dayjs(expiryDate).isValid()
            || !summary || summary == ""
            || !contentData || contentData == ""
            || !selectedRegion || !countryList.find(e => e.code==selectedRegion.code)
            || !selectedGateways || !paymentGatewayList.find(e => e==selectedGateways)
            || !selectedProduct || !productList.find(e => e.code==selectedProduct.code)}
            <br/>
            selectedType === ''{''+ selectedType === ''}<br/>
            || !expiryDate {''+ !expiryDate }<br/>
            || !dayjs(expiryDate).isValid(){''+ !dayjs(expiryDate).isValid()}<br/>
            || !summary {''+ !summary }<br/>
            || summary == "" {''+ summary == ""}<br/>
            || !contentData {''+  !contentData }<br/>
            || contentData == "" {''+ contentData == ""}<br/>
            || !selectedRegion {''+ !selectedRegion }<br/>
            || !countryList.find {''+ !countryList.find(e => e.code==selectedRegion.code)}<br/>
            || !selectedGateways {''+ !selectedGateways }<br/>
            || !paymentGatewayList.find {''+  !paymentGatewayList.find(e => e==selectedGateways)}<br/>
            || !selectedProduct {''+ !selectedProduct }<br/>
            || !productList.find {''+ !productList.find(e => e.code==selectedProduct.code)}<br/>

            </div>}
            <div>
                {/*  type */}
                <div className="alert-textfield">
                    <TextField
                        id="outlined-select-alert-type"
                        select
                        required
                        label="Alert Type Selection"
                        value={selectedType}
                        onChange={ (e) => setSelectedType(e.target.value) }
                        size="small"
                        helperText="The alert type which will indicate what icon will be displayed."
                        >
                        {alertTypes.map((option) => (
                            <MenuItem key={option} value={option}>
                                {getIconForAlertType(option)}
                                {getLabelForAlertType(option)}
                            </MenuItem>
                        ))
                        }
                    </TextField>
                </div>

                {/* expiry */}
                <div className="alert-textfield">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker label="Alert Expiry Date"
                        minDate={expiryDate}
                        value={expiryDate}
                        onChange={(newValue) => {setExpiryDate(newValue); setErrorDate(newValue? '': 'empty'); }}
                        onError={(newError) => setErrorDate(newError)}
                        slotProps={{
                            textField: {
                            error: errorDateMessage !== '',
                            helperText: errorDateMessage,
                            },
                        }}
                        required
                        />
                    </LocalizationProvider>
                </div>

                {/* summary */}
                <div className="alert-textfield">
                    <TextField
                        id="standard-helperText"
                        label="Alert Summary"
                        size="small"
                        helperText={summaryChanged && summary.length == 0?
                            "The summary cannot be empty"
                            : <>({summary.length}/100) <br />Enter a short description or the summary of the alert.<br /> It will also be used as a title.<br /> </>}
                        variant="outlined"
                        value={summary}
                        inputProps={{ maxLength: 100 }}
                        error={summaryChanged && summary.length == 0}
                        onChange={(e) => { setSummaryChanged(true); setSummary(e.target.value)}}
                        required
                />
                </div>

                {/*  content */}
                <div className="alertContentData alert-textfield">
                    <div  style={{ width: '47%', marginRight: '4%' }} className="textfield">
                        <TextField
                            id="outlined-multiline-static"
                            style={{ width: '100%' }}
                            label="Alert Content"
                            multiline
                            minRows={4}
                            inputProps={{ maxLength: 2000 }}
                            helperText={contentChanged && contentData.length == 0? "The content cannot be empty" : "("+contentData.length+"/2000)"}
                            size="small"
                            value={contentData}
                            error={contentChanged && contentData.length == 0}
                            onChange={handleContentDataChange}
                            required
                        />
                    </div>
                    <div  style={{ width: '47%' }} className="preview">
                    <Divider textAlign="left">Content Preview</Divider>
                        <div className="overflow-word-class">
                            {parse(DOMPurify.sanitize(contentData))}
                        </div>
                    </div>
                </div>

                {/* audience */}
                <div className="alert-textfield">
                    <Divider textAlign="left">
                        <Typography variant="subtitle1" gutterBottom>
                            Alert Scope
                        </Typography>
                    </Divider>

                    <div className="alert-textfield">
                    <Autocomplete
                        id="alert-target-country"
                        autoComplete
                        options={countryList}
                        limitTags={1}
                        value={selectedRegion}
                        onChange={handleRegionChange}
                        getOptionLabel={(option)=>(option?option.label:'')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                label="Target Countries"
                                helperText="Countries the alert will be visible to"
                                error={!selectedRegion}
                            />
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                    />
                    </div>

                    <div className="alert-textfield">
                    <Autocomplete
                        id="alert-target-gateway"
                        options={initGatewayList}
                        limitTags={1}
                        value={selectedGateways}
                        onChange={handleGatewayChange}
                        getOptionDisabled={(option) =>
                            !paymentGatewayList.includes(option)
                        }
                        getOptionLabel={(option)=>(option?option:'')}
                        //isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                label="Target Payment Gateways"
                                error={!selectedGateways}
                                helperText={ !selectedGateways ? "Kindly select a valid payment gateway." :
                                <>Payment Gateways the alert will be visible to.<br /> Options are limited by the selected target country</>}
                            />
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}  key={option}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                    />
                    </div>

                    <div className="alert-textfield">
                    <Autocomplete
                        id="alert-target-products"
                        autoComplete
                        options={productList}
                        limitTags={1}
                        value={selectedProduct}
                        getOptionLabel={(option)=>(option?option.label:'')}
                        onChange={handleSelectedProductChange}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                label="Target Product"
                                error={!selectedProduct}
                                helperText="Products the alert will be visible to"
                            />
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}

                    />
                    </div>
                </div>

            </div>
        </div>
    );
}