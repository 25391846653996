import React, { useEffect, useState } from "react";

import StepWizard from "react-step-wizard";
import {useTranslation} from "react-i18next";

import MachineDetails from "./MachineDetails";
import MachineSelection from "./MachineSelection";
import Summary from "./Summary";
import Processing from "./Processing";
import Result from "./Result";
import Nav from "./Nav";

import transitions from './../common/transitions.less';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './remotestart.css'

export default function RemoteStartRoot() {

    const {t, i18n} = useTranslation('common');

    const [siteList, setSiteList] = useState([])
    const [selectedSiteId, setSelectedSiteId] = useState('')
    const [selectedSiteIdentifier, setSelectedSiteIdentifier] = useState('')
    const [machineList, setMachineList] = useState([])
    const [selectedMachine, setSelectedMachine] = useState({})
    const [vendAmount, setVendAmount] = useState([])
    const [promoCodes, setPromoCodes] = useState([])
    const [selectedPromoCode, setSelectedPromoCode] = useState({})
    const [isProcessingForm, setProcessingForm] = useState(false)
    const [isMachinesLoading, setMachinesLoading] = useState(false)
    const [isPromosLoading, setPromosLoading] = useState(false)
    const [processingResponse, setProcessingResponse] = useState({})
    const [width, setWidth] = React.useState(window.innerWidth);
    const [SW, setSWInstance] = useState({})

    // For retrieving site list on load
    useEffect(() => {
        fetch("/api/remoteStart/sites", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
        .then(response => response.json())
        .then(
            res => { 
                setSiteList(res)

                if(res.length == 0) {
                    toast.warn("Sites list is empty !", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    setSelectedSiteId(res[0].value);
                    setSelectedSiteIdentifier(res[0].detail)
                }
            }
        )
        .catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
            toast.error("An error occurred while fetching the sites list.", {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }, [])

    // For retrieving machine list whenever selectedSiteId changes
    useEffect(() => {
        if(selectedSiteId != '') {
            //Reset machine
            setSelectedMachine({})
            setMachineList([])
            setMachinesLoading(true)

            fetch("/api/remoteStart/machines?siteId="+selectedSiteId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then(response => response.json())
            .then(
                res => { 
                    setMachineList(res)

                    if(res.length == 0) {
                        toast.warn("Machines list is empty !", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            )
            .catch(error => {
                console.error('There was a problem with the Fetch operation:', error);
                toast.error("An error occurred while fetching the machines list", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .finally(() => {
                setMachinesLoading(false)
            });
        }
    }, [selectedSiteId])

    // For retrieving promos whenever selectedSiteId changes
    useEffect(() => {
        if(selectedSiteId != '') {
            //Reset
            setPromosLoading(true)
            setPromoCodes([])
            setSelectedPromoCode({});

            fetch("/api/remoteStart/getCodes?siteIdentifier="+selectedSiteIdentifier, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then(response => response.text())
            .then(
                res => { 
                    const codesList = JSON.parse(res).map((x, index) => {return {'id': Math.random().toString(36).substring(2, 5), 'code': x} })
                    setPromoCodes(codesList)
                    if(codesList.length > 0){
                        setSelectedPromoCode(codesList[0]);
                    } else {
                        toast.warn("Promo codes list is empty !", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setSelectedPromoCode({});
                    }
                }
            )
            .catch(error => {
                console.error('There was a problem with the Fetch Promos operation:', error);
                toast.error("An error occurred while fetching the promos list", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .finally(() => {
                setPromosLoading(false)
            });
        }
    }, [selectedSiteIdentifier])

    // For resizing
    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, [])


    const handleSiteDropdownChange = (e) => {
        setSelectedSiteId(e.target.value);
        
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option =  optionElement.getAttribute('detail');
        setSelectedSiteIdentifier(option)
    }

    const handleMachineSelection  = (ids) => {
        const machineId = ids[0]
        const machineData = machineList.find((row) =>
            row.id == machineId
        ) || {};
      
        setSelectedMachine(machineData)
        setVendAmount(machineData.defaultAmount);
    }

    const handleVendAmountChange = (e) => {
        const amount = e.target.value
        var validAmount = amount;
        if(amount != '') {
            if(amount < selectedMachine.minAmount) {
                validAmount = selectedMachine.minAmount

                toast.warn("The vend amount was limited to the machine's minimum amount configuration.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else if (amount > selectedMachine.maxAmount) {
                validAmount = selectedMachine.maxAmount

                toast.warn("The vend amount was limited to the machine's maximum amount configuration.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        setVendAmount(validAmount);
    }

    const handlePromoSelection  = (ids) => {
        const promoId = ids[0]
        const promoData = promoCodes.find((row) =>
            row.id == promoId
        ) || {};
      
        setSelectedPromoCode(promoData);
    }

    const handleFormSubmission  = () => {
        setProcessingForm(true)
        const csrfKey = document.getElementById("csrftoken_").name
        const csrfValue = document.getElementById("csrftoken_").value
        const csrfParamsKey =document.getElementById("params_").name
        const csrfParamsValue =document.getElementById("params_").value 

        const formData = new FormData();
        formData.append("machineId", selectedMachine.uuid)
        formData.append("vendAmount", vendAmount)
        formData.append("remoteStartCode", selectedPromoCode.code)
        formData.append(csrfKey, csrfValue)
        formData.append(csrfParamsKey, csrfParamsValue)


        fetch('/api/remoteStart/initiate', {
            method: 'POST',
            body: formData,
        })
        .then((res) => res.json())
        .then((result) => { 
            // TODO Parse for errors
            setProcessingResponse(result)
            setProcessingForm(false)
        })
        .catch((err) => {
            console.error('There was a problem with the Form Submission operation:', err);
            setProcessingResponse(err)
            setProcessingForm(false)
            
            toast.error("An error occurred while processing the request", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        .finally(() => {
            SW.nextStep()
        });
        
    }

    const restartForm = () => {
        setSelectedSiteId(siteList[0].value);
        setSelectedSiteIdentifier(siteList[0].detail)
    }
    
    return (
        <div id="remoteStartRoot">
            {/* <h1>{t('remoteStart.title')}</h1> */}
            <StepWizard 
                nav={width > 768 && <Nav />}
                instance={setSWInstance}
                className="step-wizard"
            >
                <MachineSelection 
                    handleSiteDropdownChange={handleSiteDropdownChange}
                    handleMachineSelection={handleMachineSelection}
                    isMachinesLoading={isMachinesLoading}
                    windowWidth={width}
                    siteList={siteList}
                    selectedSiteId={selectedSiteId}
                    machineList={machineList}
                    selectedMachine={selectedMachine}
                />
                <MachineDetails 
                    promoCodes={promoCodes}
                    isPromosLoading={isPromosLoading}
                    windowWidth={width}
                    machineData={selectedMachine}
                    vendAmount={vendAmount}
                    selectedPromoCode={selectedPromoCode}
                    handleVendAmountChange={handleVendAmountChange}
                    handlePromoSelection={handlePromoSelection}
                />
                <Summary 
                    machine={selectedMachine}
                    windowWidth={width}
                    vendAmount={vendAmount} 
                    promoCode={selectedPromoCode}
                    handleFormSubmission={handleFormSubmission}
                />
                <Processing 
                    isProcessingForm={isProcessingForm}
                    windowWidth={width}
                />
                <Result 
                    processingResponse={processingResponse}
                    selectedSiteId={selectedSiteId}
                    restartForm={restartForm} 
                    windowWidth={width}
                />
            </StepWizard>
            <ToastContainer />
        </div>
    );
  }