import React, { Component, StrictMode } from "react";
import ReactDOM from 'react-dom';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";

import RemoteStartRoot from "./remoteStart/RemoteStartRoot";
import AlertsAdminRoot from "./alerts/management/AlertsAdminRoot";
import Alerts from "./alerts/Alerts";

class Main extends Component {
    render() {
        return (
            <div>
                {/* <RemoteStartRoot /> */}
                <AlertsAdminRoot />
            </div>
        );
    }
}

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
    },
});

if(document.getElementById('react-mountpoint')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <Main/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-mountpoint')
    );
}

if(document.getElementById('react-mountpoint-remotestart')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <RemoteStartRoot/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-mountpoint-remotestart')
    );
}

if(document.getElementById('react-alert-mountpoint')) {
    ReactDOM.render(
        <StrictMode>
            <I18nextProvider i18n={i18next}>
                <Alerts/>
            </I18nextProvider>
        </StrictMode>,
        document.getElementById('react-alert-mountpoint')
    );
}