import React from "react";
import MobileFooter from "./MobileFooter";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

export default function Result(props) {
    
    const { processingResponse, selectedSiteId, firstStep, windowWidth, restartForm } = props;

    const goToFirstStep = () => {
        firstStep()
        restartForm()
    } 

    const redirect = () => {
        window.location.replace("/machines/?siteId="+selectedSiteId);
    }

    return (
        <div className="step-container">
            <div className="step-header">
                <PublishedWithChangesIcon />
                Result
                <div className="wizard-step-info">Step  {props.currentStep} of {props.totalSteps} </div>
            </div>
            <div className="step-content">
                <div>
                    <h3 className={'text-center'}>Processing Completed.</h3>
                    <div className={'text-center'}>The request has been completed with the following details:</div>

                    {/* {"code":200,"info":"Success","customerReference":"ZD3-PE8","promotionCode":"5 Dudley Street Laundry Room","siteName":"Success","result":"Dryer #3","machineName":"0","cycleTime":""} */}

                    { processingResponse.code == 200 ? (
                        <div className="processing-response">
                            <div>
                                <div className="summary-detail-label">Customer Reference</div>
                                <div className="summary-detail-value">{processingResponse.customerReference}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">Promotion Code</div>
                                <div className="summary-detail-value">{processingResponse.promotionCode}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">Site Name</div>
                                <div className="summary-detail-value">{processingResponse.siteName}</div>
                            </div>
                            <div>
                                <div className="summary-detail-label">Machine Name</div>
                                <div className="summary-detail-value">{processingResponse.machineName}</div>
                            </div>
                            { processingResponse.result == "Success" ?
                                (
                                    <div>
                                        <div className="summary-detail-label">Result</div>
                                        <div className="summary-detail-value" style={{color: "green"}}>{processingResponse.result}</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="summary-detail-label">Result</div>
                                        <div className="summary-detail-value" style={{color: "red"}}>{processingResponse.result}</div>
                                        <div className="summary-detail-value">It might be that the machine cannot be credited remotely.  
                                        Kindly check the machine status if it is connected to the internet <a href={'/machines/status?siteIdentifier='+processingResponse.siteIdentifier}>here</a>.
                                        </div>
                                    </div>
                                )
                            }
                            <div>
                                <div className="summary-detail-label">Cycle Time</div>
                                <div className="summary-detail-value">{processingResponse.cycleTime}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            An error occured while processing your request.
                        </div>
                    )}
                </div>

                <div className="step-footer">
                { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={false}
                                disabledNext={false}
                                onClickBack={goToFirstStep}
                                onClickNext={redirect}
                            />
                        ) : 
                        (<div>
                            <button className='btn btn-default btn-block' onClick={goToFirstStep}>Start a new request</button>
                            <button className='btn btn-success btn-block' onClick={redirect}>Finish</button>
                        </div>
                        )}
                </div>
            </div>
        </div>
    );
}