import React from "react";
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import MobileFooter from "./MobileFooter";

export default function Summary(props) {
    
    const { machine, vendAmount, promoCode, windowWidth, nextStep, previousStep, handleFormSubmission } = props;

    const proceedToNext = () => {
        nextStep()
        handleFormSubmission()
    } 

    return (
        <div className="step-container">
            <div className="step-header">
                <SummarizeRoundedIcon />
                Summary
                <div className="wizard-step-info">Step  {props.currentStep} of {props.totalSteps} </div>
            </div>
            <div className="step-content">
                <p className='text-center'>Kindly review the details below and click on the submit button to start the request processing.</p>
                <div >
                    <div>
                        <div className="summary-detail-label">Machine</div>
                        <div className="summary-detail-value">{machine.name}</div>
                    </div>
                    <div>
                        <div className="summary-detail-label">Vend Amount</div> 
                        <div className="summary-detail-value">{Number(vendAmount).toFixed(2)} </div>
                    </div>
                    <div>
                        <div className="summary-detail-label">Promo Code</div> 
                        <div className="summary-detail-value">{promoCode.code}</div>
                    </div>

                    <div className="step-footer">
                    { windowWidth < 769 ?
                        (
                            <MobileFooter
                                currentStep={props.currentStep}
                                disabledBack={false}
                                disabledNext={false}
                                onClickNext={proceedToNext}
                                onClickBack={previousStep}
                            />
                        ) : 
                        (
                            <div>
                                <button className='btn btn-default btn-block' onClick={previousStep}>Go Back</button>
                                <button className='btn btn-primary btn-block' onClick={proceedToNext}>Submit</button>
                            </div>
                        )}   
                    </div>
                </div>
            </div>
        </div>
    );
}